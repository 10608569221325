import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isFilterArray } from '../../utils';
import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../propTypes/elasticSearch';

import FilterDrawerListItem from './FilterDrawerListItem';
import FilterOption from './FilterOption';
import FilterOptionAll from './FilterOptionAll';

const PRICE_FILTERS_COUNT = ['monthly_prices', 'funder'];
const PRICE_FILTERS_SKIP_COUNT = ['term', 'mileage', 'initial_payment'];

const FilterDrawerItem = ({
  isDesktop,
  name,
  count,
  disabled,
  options,
  excludeFromForm,
  title,
  allowAll,
  allText,
  elasticSearchProps,
}) => {
  const { selectedFilters, setSelectedFilter, removeSelectedFilters } = elasticSearchProps;

  const { totalCounts } = useSelector((state) => state.filterControls);

  const checkboxOrRadio = isFilterArray(name) ? 'checkbox' : 'radio';
  let allCount;

  if (PRICE_FILTERS_COUNT.includes(name) && totalCounts[name.replace('[]', '')] > totalCounts.count) {
    allCount = totalCounts.count;
  } else if (PRICE_FILTERS_SKIP_COUNT.includes(name)) {
    allCount = '';
  } else {
    allCount = totalCounts[name.replace('[]', '')];
  }

  if (!count) {
    if (isFilterArray(name)) {
      count = selectedFilters[name] ? selectedFilters[name].join(', ') : undefined;
    } else {
      count = selectedFilters[name] || undefined; // TODO: improve
    }
  }

  return (
    <FilterDrawerListItem
      count={count}
      title={title}
      disabled={disabled}
      isDesktop={isDesktop}
      hasSelectedOptions={!!selectedFilters[name]}
    >
      {allowAll && (
        <FilterOptionAll
          type={checkboxOrRadio}
          allText={allText}
          allCount={allCount}
          name={name}
          selectedFilters={selectedFilters}
          removeSelectedFilters={removeSelectedFilters}
          parentTitle={title}
        />
      )}
      {options.map(({ value, count: optionCount, label, prelabel, enabled }) => {
        const selected = isFilterArray(name)
          ? (selectedFilters[name] || []).indexOf(value) > -1
          : selectedFilters[name] === value;

        return (
          <FilterOption
            key={value}
            type={checkboxOrRadio}
            value={value}
            count={optionCount || ''}
            name={name}
            excludeFromForm={excludeFromForm}
            label={label}
            prelabel={prelabel}
            enabled={enabled}
            selected={selected}
            onSelect={setSelectedFilter}
            parentTitle={title}
            id={isDesktop ? `${name}-${value}-desktop` : `${name}-${value}-mobile`}
          />
        );
      })}
    </FilterDrawerListItem>
  );
};

FilterDrawerItem.propTypes = {
  isDesktop: PropTypes.bool,
  name: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes).isRequired,
  excludeFromForm: PropTypes.bool,
  title: PropTypes.string.isRequired,
  allowAll: PropTypes.bool,
  allText: PropTypes.string,
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

FilterDrawerItem.defaultProps = {
  isDesktop: false,
  count: undefined,
  disabled: false,
  excludeFromForm: false,
  allowAll: true,
  allText: 'All',
};

export default FilterDrawerItem;
